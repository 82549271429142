.banner{
    height: auto;
    width: 30%;
    object-fit: contain;
    margin-left: auto;
    margin-right: auto;
    display:block;
    margin-top: 1em;
    margin-bottom: 1em;
    min-width: 10%;
    max-width: 15%;
}